import { CanvasItem, isSlotItem, ItemHealthStatus } from 'modules/fleet-management/state/types/outlineTypes';

const hasHealthIssue = (item?: CanvasItem) => (!item || isSlotItem(item) ? false : healthIssuePredicateFn(item));

const healthIssuePredicateFn = (item: CanvasItem): boolean =>
  item.health === ItemHealthStatus.INOPERATIONAL || item.health === ItemHealthStatus.PARTLY_OPERATIONAL;

const hasItemGroupHealthIssue = (items: CanvasItem[]): boolean => items.some(healthIssuePredicateFn);

export { hasItemGroupHealthIssue, hasHealthIssue };

import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { selectStage } from 'modules/fleet-management/state/stage/stageSlice';
import store from 'state/store';
import { Area, Deck } from 'modules/fleet-management/state/types/outlineTypes';
import calculateAreaLocations from 'modules/fleet-management/utils/functions/areas';
import { MAIN_DECK } from 'modules/fleet-management/view/fleets/aircraft/outline/constants/outlineConstants';

const deckAdapter = createEntityAdapter<Deck>({
  selectId: (deck: Deck) => deck.id,
});

const areaAdapter = createEntityAdapter<Area>({
  selectId: (area: Area) => area.id,
});

export type AircraftOutlineState = {
  decks: EntityState<Deck>;
  areas: EntityState<Area>;
  error?: Error;
};

export const initialState: AircraftOutlineState = {
  decks: deckAdapter.getInitialState(),
  areas: areaAdapter.getInitialState(),
  error: undefined,
};

const aircraftOutlineSlice = createSlice({
  name: 'aircraftOutline',
  initialState,
  reducers: {
    decksReceived(state, action) {
      deckAdapter.setAll(state.decks, action.payload);
    },
    resetDecks(state) {
      deckAdapter.removeAll(state.decks);
    },

    areasReceived(state, action) {
      areaAdapter.setAll(state.areas, action.payload);
    },
    resetAreas(state) {
      areaAdapter.removeAll(state.areas);
    },
  },
});

export const { decksReceived, resetDecks, areasReceived, resetAreas } = aircraftOutlineSlice.actions;

type RootState = ReturnType<typeof store.getState>;

export const areaSelectors = areaAdapter.getSelectors<RootState>((state) => state.aircraftOutline.areas);

export const selectAreas = createSelector([areaSelectors.selectAll, selectStage], (areas, stage) =>
  calculateAreaLocations(
    areas.filter((area) => area.deck.name === MAIN_DECK),
    stage.dimensions.deck
  )
);

export const selectAreaById = createSelector([selectAreas, (_, areaId) => areaId], (areas, areaId) =>
  areas.find(({ id }) => id === areaId)
);

export default aircraftOutlineSlice.reducer;

import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { SOFTWARE_COMPONENT_INSTALLATION_DATA_LOCATION } from 'common/environment';
import store from 'state/store';
import { SoftwareApp, SoftwareComponentInstallation } from 'modules/fleet-management/state/types/outlineTypes';
import { sortEventsByTimestamp } from 'modules/fleet-management/utils/functions/sortEventsByTimestamp';
import fetchContentFromAzureBlob from 'modules/fleet-management/utils/functions/fetchContentFromAzureDataService';

const softwareComponentInstallationsAdapter = createEntityAdapter<SoftwareComponentInstallation>({
  selectId: (item: SoftwareComponentInstallation) => item.id,
});

export type Software = {
  softwareApps: SoftwareApp[];
  softwareComponentInstallations: SoftwareComponentInstallation[];
};

export type SoftwareComponentInstallationState = {
  items: EntityState<SoftwareComponentInstallation>;
  error?: Error;
};

export const initialState: SoftwareComponentInstallationState = {
  items: softwareComponentInstallationsAdapter.getInitialState(),
  error: undefined,
};

export const fetchSoftwareAppsAndComponentInstallationsByTailsign = createAsyncThunk<Software, string>(
  'fleets/fetchSoftwareAppsAndComponentInstallationsByTailsign',
  async (tailsign: string): Promise<Software> => await fetchContentFromAzureBlob(SOFTWARE_COMPONENT_INSTALLATION_DATA_LOCATION, tailsign)
);

const softwareComponentInstallationSlice = createSlice({
  name: 'softwareComponentInstallation',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.pending, (state) => {
        state.error = undefined;
        softwareComponentInstallationsAdapter.removeAll(state.items);
      })
      .addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.fulfilled, (state, action) => {
        state.error = undefined;
        const sortedData = sortEventsByTimestamp(action.payload.softwareComponentInstallations);
        softwareComponentInstallationsAdapter.setAll(state.items, sortedData);
      })
      .addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.rejected, (state, payload) => {
        state.error = payload.error as Error;
        softwareComponentInstallationsAdapter.removeAll(state.items);
      }),
});

type RootState = ReturnType<typeof store.getState>;

const softwareComponentInstallationsEntitySelectors = softwareComponentInstallationsAdapter.getSelectors<RootState>(
  (state) => state.softwareComponentInstallation.items
);

export const selectSoftwareComponentInstallations = softwareComponentInstallationsEntitySelectors.selectAll;

export default softwareComponentInstallationSlice.reducer;

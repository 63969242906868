export const CONSTANT_HEADER_HEIGHT = 72 + 1; /* Header height + 1px border */
export const ZOOM_SCALE_FACTOR = 1.1;
export const ZOOM_SCALE_LOWER_LIMIT = 0.5;
export const ZOOM_SCALE_UPPER_LIMIT = 5;
export enum ZOOM {
  IN,
  OUT,
}
export const ZOOM_KEYS = {
  PAGE_UP: 'PageUp',
  PAGE_DOWN: 'PageDown',
};

export const Z_INDEXES = {
  ABOVE_CANVAS: 1,
  TOP: 1000,
};

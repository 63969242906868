import { ChevronRight, SubHeader, TextLink } from '@control-tower/aerq-ui-library';
import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoadingOverlay } from 'utils/functions/toggleLoadingOverlay';

const NotFound = () => {
  const { t: translate } = useTranslation();

  useEffect(() => hideLoadingOverlay(), []);

  const handleClick = () => {
    window.location.replace('/');
  };

  return (
    <Stack p={4} spacing={3}>
      <SubHeader title={translate('notFoundPage.title')} bottomMargin={2} />

      <Typography color="grey.600" variant="h2" fontWeight={400} pb={3}>
        {translate('notFoundPage.code')}
      </Typography>

      <Typography color="grey.900" variant="h3" fontWeight={300}>
        {translate('notFoundPage.pageWasMoved')}
      </Typography>

      <TextLink onClick={handleClick} underline="hover" color="primary" endIcon={<ChevronRight />}>
        {translate('notFoundPage.goToHome')}
      </TextLink>
    </Stack>
  );
};

export default NotFound;

import { DateTime } from 'luxon';
export const DATE_FORMAT_WITH_DAY_AND_YEAR = 'MMM dd, yyyy HH:mm';
export const TABLE_DATE_FORMAT = 'LLL dd, HH:mm';
export const EVENTS_DATE_FORMAT = 'MM/dd/yyyy';
export const EVENTS_TIME_FORMAT = 'HH:mm';
export const INVALID_DATE_TIME_STRING = 'Invalid DateTime';

export class DateTimeConverter {
  private date: DateTime;
  constructor(isoTimestamp: string, hideInvalidDateWarning = false) {
    this.date = DateTime.fromISO(isoTimestamp);

    if (!this.date.isValid && !hideInvalidDateWarning) {
      console.warn('Could not parse invalid datetime');
    }
  }

  public getDate(): DateTime {
    return this.date;
  }

  public convertToUtc(): this {
    this.date = this.date.setZone('utc');
    return this;
  }

  public formatToTableFormat(): string {
    return this.date.toFormat(TABLE_DATE_FORMAT);
  }

  public formatToLongFormat(): string {
    return this.date.toFormat(DATE_FORMAT_WITH_DAY_AND_YEAR);
  }

  public formatToEventDate(): string {
    return this.date.toFormat(EVENTS_DATE_FORMAT);
  }

  public formatToEventTime(): string {
    return this.date.toFormat(EVENTS_TIME_FORMAT);
  }
}

export function sortArrayByCreatedAt<T extends { createdAt: string }>(arr: T[]): T[] {
  return [...arr].sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
}

export function sortArrayByLastUpdatedAt<T extends { lastUpdatedAt: string }>(arr: T[]): T[] {
  return [...arr].sort((a, b) => new Date(b.lastUpdatedAt).valueOf() - new Date(a.lastUpdatedAt).valueOf());
}

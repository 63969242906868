import { Snackbar } from '@control-tower/aerq-ui-library';
import { Message } from 'common/types';
import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { FunctionComponent, useEffect, useCallback } from 'react';
import { connect, Matching } from 'react-redux';
import { removeMessage } from 'state/message/actions';
import { getMessages } from 'state/message/selectors';
import { Dispatch, State } from 'state/store';

const Messages: FunctionComponent<
  Matching<{ messages: never[] } & { removeMessage: (key: string) => void }, { messages: never[]; removeMessage: Dispatch }>
> = (props) => {
  const { messages, removeMessage } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getContent = useCallback(
    (contentKey: SnackbarKey | undefined, message: string | undefined, options: OptionsObject) => {
      const { variant } = options || { variant: undefined };
      return (
        <Snackbar
          onClick={() => {
            closeSnackbar(contentKey);
          }}
          message={message}
          variant={variant}
        />
      );
    },
    [closeSnackbar]
  );

  useEffect(() => {
    (messages as Array<Message>).forEach(({ key, message, options = {} }: Message) => {
      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        content: (contentKey) => getContent(contentKey, message, options),
      });

      // Remove message from state
      removeMessage(key);
    });
  }, [closeSnackbar, enqueueSnackbar, messages, removeMessage, getContent]);

  return null;
};

const mapStateToProps = (state: State) => ({
  messages: getMessages(state),
});

const mapDispatchToProps = {
  removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

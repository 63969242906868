const emptyResultWithErrorLog = (toLog: string | unknown) => {
  console.error(toLog);
  return [];
};

export const fetchContentFromAzureBlob = async (dataLocation: string, tailsign: string) => {
  try {
    const response = await fetch(`${dataLocation}/${tailsign}.json`);

    if (!response.ok) {
      return emptyResultWithErrorLog('No proper response received from the blob.');
    }

    const blob = await response.blob();

    if (!blob) {
      return emptyResultWithErrorLog('The blob was not found or is undefined.');
    }

    const blobContent = await blob.text();

    if (!blobContent) {
      return emptyResultWithErrorLog('No blob content found. Seems to be empty.');
    }

    return JSON.parse(blobContent);
  } catch (error) {
    return emptyResultWithErrorLog(error);
  }
};

export default fetchContentFromAzureBlob;

import { configureStore } from '@reduxjs/toolkit';
import contentDeploymentReducer, { ContentDeploymentState } from 'modules/fleet-management/state/content/contentDeploymentSlice';
import outlineFilterReducer, { OutlineItemsFilterState } from 'modules/fleet-management/state/filters/outlineItemsFiltersSlice';
import fleetReducer, { FleetsState } from 'modules/fleet-management/state/fleets/fleetsSlice';
import hardwareReducer, { AircraftHardwareState } from 'modules/fleet-management/state/hardware/hardwareSlice';
import lopaDescriptionReducer, { LopaDescriptionState } from 'modules/fleet-management/state/lopa/lopaDescriptionSlice';
import lopaReducer, { AircraftLopaState } from 'modules/fleet-management/state/lopa/lopaSlice';
import lopaVersionsReducer, { LopaVersionsState } from 'modules/fleet-management/state/lopa/lopaVersionsByAircraftTypeSlice';
import unconfiguredAircraftConfigReducer, {
  UnconfiguredAircraftConfigState,
} from 'modules/fleet-management/state/newAircraft/unconfiguredAircraftConfigSlice';
import aircraftOutlineInteractionStatusReducer, {
  AircraftOutlineInteractionState,
} from 'modules/fleet-management/state/outline/aircraftOutlineInteractionSlice';
import aircraftOutlineReducer, { AircraftOutlineState } from 'modules/fleet-management/state/outline/aircraftOutlineSlice';
import softwareAppsReducer, { SoftwareAppsState } from 'modules/fleet-management/state/software/softwareAppsSlice';
import softwareComponentInstallationReducer, {
  SoftwareComponentInstallationState,
} from 'modules/fleet-management/state/software/softwareComponentInstallationSlice';
import stageReducer, { StageState } from 'modules/fleet-management/state/stage/stageSlice';
import messageReducer, { MessageState } from './message/reducer';
import configurationItemReducer, {
  ConfigurationItemsState,
} from 'modules/integration-center/state/configurationItems/configurationItemSlice';
import configurationsReducer, { ConfigurationsState } from 'modules/integration-center/state/configurations/configurationsSlice';
import EnvironmentsReducer, { EnvironmentsState } from 'modules/integration-center/state/environments/environmentsSlice';
import EnvironmentCreationReducer, {
  EnvironmentCreationState,
} from 'modules/integration-center/state/environments/environmentCreationSlice';

export const store = configureStore({
  reducer: {
    message: messageReducer,
    fleets: fleetReducer,
    unconfiguredAircraftConfig: unconfiguredAircraftConfigReducer,
    stage: stageReducer,
    outlineFilters: outlineFilterReducer,
    lopaVersions: lopaVersionsReducer,
    lopaDescription: lopaDescriptionReducer,
    aircraftOutlineInteraction: aircraftOutlineInteractionStatusReducer,
    aircraftOutline: aircraftOutlineReducer,
    lopa: lopaReducer,
    hardware: hardwareReducer,
    softwareComponentInstallation: softwareComponentInstallationReducer,
    softwareApps: softwareAppsReducer,
    contentDeployment: contentDeploymentReducer,
    configurations: configurationsReducer,
    configurationItems: configurationItemReducer,
    environments: EnvironmentsReducer,
    environmentCreation: EnvironmentCreationReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        warnAfter: 128,
        ignoredActions: [
          // Reason: YAML response in base64 encoding
          'IC/fetchConfigurationItemManifest/fulfilled',
          // Reason: YAML response in base64 encoding
          'IC/fetchConfigurationItemTypeDefinition/fulfilled',
        ],
      },
    }),
});

export type Dispatch = typeof store.dispatch;

export type State = {
  message: MessageState;
  fleets: FleetsState;
  unconfiguredAircraftConfig: UnconfiguredAircraftConfigState;
  stage: StageState;
  outlineFilters: OutlineItemsFilterState;
  lopaVersions: LopaVersionsState;
  lopaDescription: LopaDescriptionState;
  aircraftOutlineInteraction: AircraftOutlineInteractionState;
  aircraftOutline: AircraftOutlineState;
  lopa: AircraftLopaState;
  hardware: AircraftHardwareState;
  softwareComponentInstallation: SoftwareComponentInstallationState;
  softwareApps: SoftwareAppsState;
  contentDeployment: ContentDeploymentState;
  configurations: ConfigurationsState;
  configurationItems: ConfigurationItemsState;
  environments: EnvironmentsState;
  environmentCreation: EnvironmentCreationState;
};

export default store;

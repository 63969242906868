import { ADD_MESSAGE, DELETE_MESSAGE } from './actions';
import { AnyAction } from 'redux';
import { Message } from 'common/types';

const initialState = {
  messages: [],
};

const messageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ADD_MESSAGE: {
      return { ...state, messages: { ...state.messages, ...action.payload } };
    }
    case DELETE_MESSAGE: {
      return {
        ...state,
        messages: [...(state.messages as Array<Message>).filter((message) => message.key !== action.payload)],
      };
    }
    default:
      return state;
  }
};
export type MessageState = typeof initialState;

export default messageReducer;

import { ItemSubType, SlotItemSubType } from 'modules/fleet-management/state/types/itemSubTypes';
import { FuselageAlignment, Lopa, LopaArea, LopaDevice, LopaRegionData } from 'modules/fleet-management/state/types/lopaTypes';
import { Area, CanvasItemTypes, ConnectionType, Deck, ItemConnection, SlotItem } from 'modules/fleet-management/state/types/outlineTypes';
import { capitalizeFirstLetter } from 'utils/functions/stringFunctions';
import mergeNeighbours from './mergeNeighbours';

const mockDependable: ItemConnection[] = [
  {
    description: 'SPM',
    destination: {
      id: 'SPM-31LEFT',
      type: CanvasItemTypes.SlotItem,
      subType: SlotItemSubType.SPM,
    },
    additionalData: {
      devicePort: 'eth-9',
      devicePortType: 'ethernet',
    },
  },
];

const mockDependedOn: ItemConnection[] = [
  {
    description: 'SPM',
    destination: {
      id: 'SPM-30LEFT',
      type: CanvasItemTypes.SlotItem,
      subType: SlotItemSubType.SPM,
    },
    additionalData: {
      devicePort: 'eth-3',
      devicePortType: 'ethernet',
    },
  },
];

const restructureLopaFile = (lopaFile: Lopa) => {
  const decks: Deck[] = [];
  const areas: Area[] = [];
  const items: SlotItem[] = [];

  lopaFile.lopa.forEach(({ region }: { region: LopaRegionData }) => {
    const existingDeck = decks.find((deck) => deck.name === region.deck);
    let currentDeck: Deck;
    if (!existingDeck) {
      const newDeck: Deck = { id: decks.length, name: region.deck };
      decks.push(newDeck);
      currentDeck = newDeck;
    } else {
      currentDeck = existingDeck;
    }

    const locations = region.locations.filter((location: LopaArea) => location.devices.length);
    locations.sort(orderLocationByOrderInRegionThenFuselageAlignment);
    const mergedLocations = mergeNeighbours(locations, locationMergeCondition, locationMerge);

    mergedLocations.forEach((location) => {
      const areaId = areas.length + currentDeck.id * 100;

      if (location.devices) {
        location.devices.forEach((device) => {
          const connections: ItemConnection[] = device.connections.map((con) => ({
            description: con.description,
            destination: {
              id: con.destination_id,
              type: CanvasItemTypes.SlotItem,
              subType: con.destination_id.split('-')[0] as ItemSubType,
            },
            additionalData: {
              devicePort: con.device_port,
              devicePortType: con.device_port_type,
            },
          }));

          items.push({
            id: items.length,
            itemId: device.id,
            gridCol: +device.grid_col,
            gridRow: +device.grid_row,
            locationPath: `${capitalizeFirstLetter(region.compartment)} / ${capitalizeFirstLetter(location.section)} / ${device.id}`,
            itemSubType: device.type as ItemSubType,
            logicalName: device.logical_name,
            displayName: `${device.id} Slot`,
            areaId,
            connectionsByType: {
              [ConnectionType.CONNECTIONS]: connections,
              [ConnectionType.DEPENDABLE]: mockDependable,
              [ConnectionType.DEPENDED_ON]: mockDependedOn,
            },
            itemType: CanvasItemTypes.SlotItem,
          });
        });
      }

      areas.push({
        id: areaId,
        deck: currentDeck,
        name: location.section,
        cols: +location.grid_col_max,
        rows: +location.grid_row_max,
        groupedColumns: location.groupedColumns,
      });
    });
  });

  return {
    decks,
    areas,
    items,
  };
};

export default restructureLopaFile;

const FUSELAGE_ALIGNMENT_NUM_VALUE_MAPPING: { [K in FuselageAlignment]: number } = {
  full_width: 0,
  left: 1,
  middle: 2,
  right: 3,
};

const alignmentNumericalValue = (alignment: FuselageAlignment) => FUSELAGE_ALIGNMENT_NUM_VALUE_MAPPING[alignment];

const orderLocationByOrderInRegionThenFuselageAlignment = (a: LopaArea, b: LopaArea) => {
  const byOrderInRegion = +a.order_in_region - +b.order_in_region;
  if (byOrderInRegion !== 0) {
    return byOrderInRegion;
  }
  return alignmentNumericalValue(a.alignment_in_fuselage) - alignmentNumericalValue(b.alignment_in_fuselage);
};

const locationMergeCondition = (a: LopaArea, b: LopaArea) => a.section === b.section;

const locationMerge = (list: LopaArea[]): LopaArea => {
  let maxColumns = 0;
  let mergedDevices: LopaDevice[] = [];
  const columns: number[] = [];

  list.forEach(({ grid_col_max, devices }) => {
    columns.push(+grid_col_max);
    mergedDevices = mergedDevices.concat(
      devices.map(({ grid_col, ...rest }) => ({
        grid_col: (maxColumns + +grid_col).toString(),
        ...rest,
      }))
    );
    maxColumns += +grid_col_max;
  });

  const first = list[0];

  return {
    alignment_in_fuselage: 'full_width',
    devices: mergedDevices,
    grid_col_max: maxColumns.toString(),
    grid_row_max: first.grid_row_max,
    id: first.id,
    order_in_region: first.order_in_region,
    section: first.section,
    zone_number: first.zone_number,
    groupedColumns: columns,
  };
};

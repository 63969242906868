import { Header } from '@control-tower/aerq-navigation-library';
import { ENV_LABEL } from 'common/environment';
import useNavigate from 'common/hooks/useNavigate';
import { Link, useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeaderController = (props: any) => {
  const location = useLocation();

  const navigateTo = useNavigate();

  return <Header Link={Link} navigateTo={navigateTo} location={location} {...props} envLabel={ENV_LABEL} />;
};

const HeaderTemplate = ({ children }: { children: React.ReactNode }) => (
  <>
    <HeaderController />
    {children}
  </>
);

export default HeaderTemplate;

import { KeycloakInstance } from 'keycloak-js';

const extractUserRoles = (keycloak: KeycloakInstance | undefined) => {
  if (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.resource_access &&
    'aerq-ops-portal' in keycloak.tokenParsed.resource_access &&
    keycloak.tokenParsed.resource_access['aerq-ops-portal'].roles
  ) {
    return keycloak.tokenParsed.resource_access['aerq-ops-portal'].roles;
  } else {
    return [];
  }
};

export default extractUserRoles;

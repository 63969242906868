import { Loadingindicator } from '@control-tower/aerq-ui-library';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'state/store';
import AppNavigation from 'view/AppNavigation';

const Root = () => (
  <Suspense fallback={<Loadingindicator />}>
    <BrowserRouter>
      <Provider store={store}>
        <AppNavigation />
      </Provider>
    </BrowserRouter>
  </Suspense>
);

export default Root;

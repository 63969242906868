export const AREA_PADDING = 10;
export const OUTLINE_THRESHOLD = 2;
export const AREA_LENGTH_MODIFIER_VALUE = 3 / 7;
export const FILTERS_HEIGHT = 100;
export const MAIN_DECK = 'main';
export const SPACE_BETWEEN_COLUMNS = 4;
export const SPACE_BETWEEN_SEATS = 1;
export const TEST_RACK_AC_TYPE = 'Test rack';
export const TEST_RACK_TAILSIGN = 'AQ-TRACK';
export const RADIAL_MENU_SIZE = 200;

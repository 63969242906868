import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RepositoryType } from '../types/RepositoryType';
import { BranchType } from '../types/BranchType';
import store from 'state/store';
import services from 'api/services';
import { AxiosError } from 'axios';

function isAxiosError(err: any): err is AxiosError<{ message: string }> {
  return err.isAxiosError === true && !!err.response?.data?.message;
}

const repositoryAdapter = createEntityAdapter<RepositoryType>({
  selectId: (item: RepositoryType) => item.id,
});

const branchAdapter = createEntityAdapter<BranchType>({
  selectId: (item: BranchType) => item.name,
});

const environmentTypeAdapter = createEntityAdapter<string>({
  selectId: (item: string) => item,
});

export type EnvironmentCreationState = {
  repositoryList: EntityState<RepositoryType>;
  branchList: EntityState<BranchType>;
  environmentTypeList: EntityState<string>;
  loading: boolean;
  error?: Error;
};

export const initialState: EnvironmentCreationState = {
  repositoryList: repositoryAdapter.getInitialState(),
  branchList: branchAdapter.getInitialState(),
  environmentTypeList: environmentTypeAdapter.getInitialState(),
  loading: false,
  error: undefined,
};

export const fetchRepositories = createAsyncThunk('IC/fetchRepositories', async () => {
  const response = await services.getRepositories();
  return response.data as RepositoryType[];
});

export const fetchBranches = createAsyncThunk('IC/fetchBranches', async (repositoryId: string) => {
  try {
    const response = await services.getBranches({ repositoryId: repositoryId });
    return response.data as BranchType[];
  } catch (err: unknown) {
    if (!isAxiosError(err)) {
      throw err;
    }
    throw new Error(err.response?.data.message);
  }
});
export const fetchEnvironmentTypes = createAsyncThunk('IC/fetchEnvironmentTypes', async () => {
  const response = await services.getEnvironmentTypes();
  return response.data as { environmentTypes: string[] };
});

const environmentCreationSlice = createSlice({
  reducers: {},
  name: 'IC/environments/create',
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(fetchRepositories.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        repositoryAdapter.removeAll(state.repositoryList);
      })
      .addCase(fetchRepositories.fulfilled, (state, action) => {
        state.error = undefined;
        repositoryAdapter.setAll(state.repositoryList, action.payload);
        state.loading = false;
      })
      .addCase(fetchRepositories.rejected, (state, payload) => {
        state.error = payload.error as Error;
        repositoryAdapter.removeAll(state.repositoryList);
        state.loading = false;
      })
      .addCase(fetchEnvironmentTypes.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        environmentTypeAdapter.removeAll(state.environmentTypeList);
      })
      .addCase(fetchEnvironmentTypes.fulfilled, (state, action) => {
        state.error = undefined;
        environmentTypeAdapter.setAll(state.environmentTypeList, action.payload.environmentTypes);
        state.loading = false;
      })
      .addCase(fetchEnvironmentTypes.rejected, (state, payload) => {
        state.error = payload.error as Error;
        environmentTypeAdapter.removeAll(state.environmentTypeList);
        state.loading = false;
      })

      .addCase(fetchBranches.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        branchAdapter.removeAll(state.branchList);
      })
      .addCase(fetchBranches.fulfilled, (state, action) => {
        state.error = undefined;
        branchAdapter.setAll(state.branchList, action.payload);
        state.loading = false;
      })
      .addCase(fetchBranches.rejected, (state, payload) => {
        state.error = payload.error as Error;
        branchAdapter.removeAll(state.branchList);
        state.loading = false;
      }),
});

type RootState = ReturnType<typeof store.getState>;

const repositoryEntitySelectors = repositoryAdapter.getSelectors<RootState>((state) => state.environmentCreation.repositoryList);
export const selectRepositories = repositoryEntitySelectors.selectAll;

const branchEntitySelectors = branchAdapter.getSelectors<RootState>((state) => state.environmentCreation.branchList);
export const selectBranches = branchEntitySelectors.selectAll;

const environmentTypeEntitySelectors = environmentTypeAdapter.getSelectors<RootState>(
  (state) => state.environmentCreation.environmentTypeList
);
export const selectEnvironmentType = environmentTypeEntitySelectors.selectAll;

export default environmentCreationSlice.reducer;

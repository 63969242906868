import { InstallationEvent } from '../../state/types/outlineTypes';

export function sortEventsByTimestamp<T extends { events: InstallationEvent[] }>(items: Array<T>): Array<T> {
  items.forEach((item) => {
    if (item.events) {
      return item.events.sort((a: InstallationEvent, b: InstallationEvent) => {
        if (a.timestamp && b.timestamp) {
          return new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf();
        }
        return 1;
      });
    }
  });

  return items;
}

import { createSlice } from '@reduxjs/toolkit';
import { CONSTANT_HEADER_HEIGHT } from 'modules/fleet-management/common/constants';
import { setAircraftType } from 'modules/fleet-management/state/lopa/lopaSlice';
import { State } from 'state/store';
import { FILTERS_HEIGHT } from 'modules/fleet-management/view/fleets/aircraft/outline/constants/outlineConstants';
import { FuselageImage, getBackgroundImageByType, getBackgroundImageRatiosByType } from '../outline/getBackgroundImage';

export type BackgroundDimensions = {
  imgWidth: number;
  imgHeight: number;
  startPositionX: number;
  startPositionY: number;
};

export type StageDimensions = {
  width: number;
  height: number;
};

export type DeckDimensions = {
  posXStart: number;
  posYStart: number;
  length: number;
  height: number;
};

export type CanvasDimensions = {
  scale: number;
  bg: BackgroundDimensions;
  stage: StageDimensions;
  deck: DeckDimensions;
  drawerHeight: number;
};

export interface StageState {
  stageLoading: boolean;
  dimensions: CanvasDimensions;
  bgImage: FuselageImage;
}

const initialState: StageState = {
  stageLoading: false,
  dimensions: {
    scale: 0,
    bg: {
      imgWidth: 0,
      imgHeight: 0,
      startPositionX: 0,
      startPositionY: 0,
    },
    stage: {
      width: 0,
      height: 0,
    },
    deck: {
      posXStart: 0,
      posYStart: 0,
      length: 0,
      height: 0,
    },
    drawerHeight: 0,
  },
  bgImage: FuselageImage.DEFAULT,
};

const updateStage = (state: StageState) => {
  const { innerHeight, innerWidth } = window;
  const { imageWidth, imageHeight, padding } = getBackgroundImageRatiosByType(state.bgImage);

  const scale = innerWidth / 1450;

  const imgWidth = scale * imageWidth;
  const imgHeight = scale * imageHeight;

  const stageWidth = innerWidth;
  const stageHeight = innerHeight - CONSTANT_HEADER_HEIGHT;
  const drawerHeight = stageHeight - FILTERS_HEIGHT;

  return {
    scale,
    bg: {
      imgWidth,
      imgHeight,
      startPositionX: (stageWidth - imgWidth) / 2,
      startPositionY: (stageHeight - imgHeight) / 2,
    },
    stage: {
      width: innerWidth,
      height: stageHeight,
    },
    deck: {
      posXStart: scale * padding.left,
      posYStart: scale * padding.top,
      length: imgWidth - (scale * padding.left + scale * padding.right),
      height: imgHeight - (scale * padding.top + scale * padding.bottom),
    },
    drawerHeight,
  };
};

export const stageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    stageLoading(state) {
      state.stageLoading = true;
    },

    setStageDimensions(state) {
      const newState = updateStage(state);
      state.dimensions = { ...newState };
      state.stageLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAircraftType, (state, action) => {
      state.bgImage = getBackgroundImageByType(action.payload);
      const newState = updateStage(state);
      state.dimensions = { ...newState };
      state.stageLoading = false;
    });
  },
});

export const stageLoading = (state: State) => state.stage.stageLoading;
export const selectStage = (state: State) => state.stage;
export const { setStageDimensions } = stageSlice.actions;
export const selectFuselageImage = (state: Pick<State, 'stage'>) => state.stage.bgImage;

export default stageSlice.reducer;

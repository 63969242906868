const mergeNeighbours = <T>(list: T[], mergeCondition: (a: T, b: T) => boolean, merge: (a: T[]) => T): T[] => {
  const result: T[] = [];
  for (let i = 0; i < list.length; i++) {
    if (i !== 0 && mergeCondition(list[i], list[i - 1])) {
      // Previous item is a neighbor, therefore was merged, so this item needs to be skipped.
      continue;

      // Merge 3 Neighbours ?
    } else if (i < list.length - 2 && mergeCondition(list[i], list[i + 1]) && mergeCondition(list[i], list[i + 2])) {
      result.push(merge([list[i], list[i + 1], list[i + 2]]));

      // Merge 2 Neighbours ?
    } else if (i < list.length - 1 && mergeCondition(list[i], list[i + 1])) {
      result.push(merge([list[i], list[i + 1]]));
    } else {
      result.push(list[i]);
    }
  }
  return result;
};

export default mergeNeighbours;

import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import store from 'state/store';
import { HighOrderItemTypes, SoftwareApp } from 'modules/fleet-management/state/types/outlineTypes';
import { fetchSoftwareAppsAndComponentInstallationsByTailsign } from './softwareComponentInstallationSlice';

const softwareAppsAdapter = createEntityAdapter<SoftwareApp>({
  selectId: (item: SoftwareApp) => item.id,
});

export type SoftwareAppsState = {
  items: EntityState<SoftwareApp>;
  error?: Error;
};

export const initialState: SoftwareAppsState = {
  items: softwareAppsAdapter.getInitialState(),
  error: undefined,
};

const softwareAppsSlice = createSlice({
  name: 'softwareApps',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.pending, (state) => {
        state.error = undefined;
        softwareAppsAdapter.removeAll(state.items);
      })
      .addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.fulfilled, (state, action) => {
        state.error = undefined;
        const softApps = action.payload.softwareApps.map((s: SoftwareApp) => ({ ...s, itemType: HighOrderItemTypes.SoftwareApp }));
        softwareAppsAdapter.setAll(state.items, softApps);
      })
      .addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.rejected, (state, payload) => {
        state.error = payload.error as Error;
        softwareAppsAdapter.removeAll(state.items);
      }),
});

type RootState = ReturnType<typeof store.getState>;

const softwareAppsEntitySelectors = softwareAppsAdapter.getSelectors<RootState>((state) => state.softwareApps.items);

export const selectSoftwareApps = softwareAppsEntitySelectors.selectAll;

export default softwareAppsSlice.reducer;

import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import services from 'api/services';
import store from 'state/store';
import { sortArrayByCreatedAt } from 'utils/functions/dateUtil';
import { ConfigurationItemType } from '../types/ConfigurationItemType';
import { ConfigurationType } from '../types/ConfigurationType';
import { EventType } from '../types/EventTypes';
import {
  PaginatedRequestType,
  PaginationListResponseType,
  PaginationListType,
  pagebleInitialState,
  paginationInitialState,
} from '../types/PaginationTypes';

const configurationsAdapter = createEntityAdapter<ConfigurationType>({
  selectId: (item: ConfigurationType) => item.id,
});

const eventItemsAdapter = createEntityAdapter<EventType>({
  selectId: (item: EventType) => item.id,
});

const configurationItemsAdapter = createEntityAdapter<ConfigurationItemType>({
  selectId: (item: ConfigurationItemType) => item.id,
});

export type ConfigurationsState = {
  items: EntityState<ConfigurationType>;
  pagination: PaginationListType;
  selectedConfigurationForTargeting: ConfigurationType | undefined;
  detailedConfiguration: {
    events: EntityState<EventType>;
    configurationItems: EntityState<ConfigurationItemType>;
  };
  loading: boolean;
  error?: Error;
};

export const initialState: ConfigurationsState = {
  items: configurationsAdapter.getInitialState(),
  pagination: paginationInitialState,
  selectedConfigurationForTargeting: undefined,
  detailedConfiguration: {
    events: eventItemsAdapter.getInitialState(),
    configurationItems: configurationItemsAdapter.getInitialState(),
  },
  loading: false,
  error: undefined,
};

const orderResponse = (data: PaginationListResponseType) => ({
  items: (data.content as ConfigurationType[]).map((config) => ({
    ...config,
  })),
  pagination: {
    ...data,
    content: [],
  },
});

export const fetchConfigurationsFromStart = createAsyncThunk('IC/fetchConfigurationsFromStart', async (searchTerm: string) => {
  const response = await services.getConfigurations({ searchTerm, ...pagebleInitialState });
  return orderResponse(response.data as PaginationListResponseType);
});

export const fetchConfigurationsNextPage = createAsyncThunk('IC/fetchConfigurationsNextPage', async (request: PaginatedRequestType) => {
  const response = await services.getConfigurations({ searchTerm: request.searchTerm, ...request.pageable });
  return orderResponse(response.data as PaginationListResponseType);
});

export const fetchConfigurationEvents = createAsyncThunk('IC/fetchConfigurationEvents', async (configId: string) => {
  const response = await services.getConfigurationEvents({ configId: configId });
  return response.data as EventType[];
});

export const fetchItemsForConfiguration = createAsyncThunk('IC/fetchItemsForConfiguration', async (configId: string) => {
  const response = await services.getItemsForConfiguration({ configId: configId });
  return response.data as ConfigurationItemType[];
});

const configurationsSlice = createSlice({
  name: 'IC/configurations',
  initialState,
  reducers: {
    setSelectedConfigurationForTargeting(state, action) {
      return { ...state, selectedConfigurationForTargeting: action.payload };
    },
    resetSelectedConfigurationForTargeting(state) {
      return { ...state, selectedConfigurationForTargeting: undefined };
    },
  },
  extraReducers: (builder) =>
    builder
      // Initial loadings
      .addCase(fetchConfigurationsFromStart.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchConfigurationsFromStart.fulfilled, (state, action) => {
        configurationsAdapter.removeAll(state.items);
        state.error = undefined;
        configurationsAdapter.setAll(state.items, sortArrayByCreatedAt(action.payload.items));
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchConfigurationsFromStart.rejected, (state, payload) => {
        state.error = payload.error as Error;
        configurationsAdapter.removeAll(state.items);
        state.loading = false;
      })
      // Next page loadings
      .addCase(fetchConfigurationsNextPage.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchConfigurationsNextPage.fulfilled, (state, action) => {
        state.error = undefined;
        configurationsAdapter.addMany(state.items, sortArrayByCreatedAt(action.payload.items));
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchConfigurationsNextPage.rejected, (state, payload) => {
        state.error = payload.error as Error;
        state.loading = false;
      })
      // Configuration Events
      .addCase(fetchConfigurationEvents.pending, (state) => {
        state.error = undefined;
        eventItemsAdapter.removeAll(state.detailedConfiguration.events);
      })
      .addCase(fetchConfigurationEvents.fulfilled, (state, action) => {
        state.error = undefined;
        eventItemsAdapter.setAll(state.detailedConfiguration.events, sortArrayByCreatedAt(action.payload));
      })
      .addCase(fetchConfigurationEvents.rejected, (state, payload) => {
        state.error = payload.error as Error;
        eventItemsAdapter.removeAll(state.detailedConfiguration.events);
      })
      // Configuration Items
      .addCase(fetchItemsForConfiguration.pending, (state) => {
        state.error = undefined;
        configurationItemsAdapter.removeAll(state.detailedConfiguration.configurationItems);
      })
      .addCase(fetchItemsForConfiguration.fulfilled, (state, action) => {
        state.error = undefined;
        configurationItemsAdapter.setAll(state.detailedConfiguration.configurationItems, sortArrayByCreatedAt(action.payload));
      })
      .addCase(fetchItemsForConfiguration.rejected, (state, payload) => {
        state.error = payload.error as Error;
        configurationItemsAdapter.removeAll(state.detailedConfiguration.configurationItems);
      }),
});

type RootState = ReturnType<typeof store.getState>;

export const { setSelectedConfigurationForTargeting, resetSelectedConfigurationForTargeting } = configurationsSlice.actions;

const configurationsEntitySelectors = configurationsAdapter.getSelectors<RootState>((state) => state.configurations.items);

export const selectConfigurations = configurationsEntitySelectors.selectAll;

export const selectConfigurationById = configurationsEntitySelectors.selectById;

export const selectSelectedConfigurationForTargeting = (state: RootState): ConfigurationType | undefined =>
  state.configurations.selectedConfigurationForTargeting;

// LOADING
export const selectConfigurationsLoadingStatus = (state: RootState): boolean => state.configurations.loading;

// EVENTS
const configurationEventsEntitySelectors = eventItemsAdapter.getSelectors<RootState>(
  (state) => state.configurations.detailedConfiguration.events
);
export const selectConfigurationEvents = configurationEventsEntitySelectors.selectAll;

// ITEMS
const configurationItemsEntitySelectors = configurationItemsAdapter.getSelectors<RootState>(
  (state) => state.configurations.detailedConfiguration.configurationItems
);
export const selectItemsForConfiguration = configurationItemsEntitySelectors.selectAll;

// PAGINATION
export const selectConfigurationsPaginationStatus = (state: RootState) => state.configurations.pagination;

export default configurationsSlice.reducer;

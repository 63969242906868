import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import services from 'api/services';
import { areasReceived, decksReceived } from 'modules/fleet-management/state/outline/aircraftOutlineSlice';
import store, { State } from 'state/store';
import { SlotItemSubType } from 'modules/fleet-management/state/types/itemSubTypes';
import { Lopa } from 'modules/fleet-management/state/types/lopaTypes';
import { SlotItem } from 'modules/fleet-management/state/types/outlineTypes';
import restructureLopaFile from 'modules/fleet-management/utils/functions/restructureLopaFile';

const lopaSlotsAdapter = createEntityAdapter<SlotItem>({
  selectId: (item: SlotItem) => item.id,
});

export const fetchLopaByTailsign = createAsyncThunk('fleets/fetchLopaByTailsign', async (tailsign: string, thunkApi) => {
  const response = await services.getLopaByTailsign({ tailsign });
  const lopa = response.data as Lopa;
  const lopaIdentifier = lopa['Lopa-Airbus-Identifier'];
  const aircraftType = lopaIdentifier['AC-Type'];

  const { decks, areas, items } = restructureLopaFile(lopa);

  thunkApi.dispatch(setAircraftType(aircraftType));
  thunkApi.dispatch(decksReceived(decks));
  thunkApi.dispatch(areasReceived(areas));

  return { areas, items };
});

export type AircraftLopaState = {
  items: EntityState<SlotItem>;
  aircraftType: string;
  error?: Error;
};

export const initialState: AircraftLopaState = {
  items: lopaSlotsAdapter.getInitialState(),
  aircraftType: '',
  error: undefined,
};

const lopaSlice = createSlice({
  name: 'lopa',
  initialState,
  reducers: {
    setAircraftType(state, action) {
      state.aircraftType = action.payload;
    },
    upsertLopaItems(state, action) {
      lopaSlotsAdapter.upsertMany(state.items, action.payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchLopaByTailsign.pending, (state) => {
        state.error = undefined;
        lopaSlotsAdapter.removeAll(state.items);
      })
      .addCase(fetchLopaByTailsign.rejected, (state, payload) => {
        state.error = payload.error as Error;
        lopaSlotsAdapter.removeAll(state.items);
      })
      .addCase(fetchLopaByTailsign.fulfilled, (state) => {
        state.error = undefined;
      }),
});

export const { setAircraftType, upsertLopaItems } = lopaSlice.actions;

type RootState = ReturnType<typeof store.getState>;

const lopaSlotEntitySelectors = lopaSlotsAdapter.getSelectors<RootState>((state) => state.lopa.items);

const selectAllLopaSlots = lopaSlotEntitySelectors.selectAll;

export const selectISDSlotsByAreaId = createSelector([selectAllLopaSlots, (_, areaId) => areaId], (slots, areaId) =>
  slots.filter((slot) => slot.areaId === areaId && slot.itemSubType === SlotItemSubType.ISD)
);

export const selectLopaSlots = lopaSlotEntitySelectors.selectAll;

export const selectSlotByItemId = createSelector([selectLopaSlots, (_, itemId) => itemId], (slots, itemId) =>
  slots.find((slot) => slot.itemId === itemId)
);

export const selectAircraftType = ({ lopa }: Pick<State, 'lopa'>) => lopa.aircraftType;

export default lopaSlice.reducer;

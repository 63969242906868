import { Message } from 'common/types';
import { VariantType } from 'notistack';
import { Dispatch } from 'state/store';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

export const addMessageSuccess = (message: Message) => ({
  type: ADD_MESSAGE,
  payload: message,
});

export const removeMessageSuccess = (key: string) => ({
  type: DELETE_MESSAGE,
  payload: key,
});

export const addMessage =
  (message: string, variant = 'error') =>
  (dispatch: Dispatch) => {
    dispatch(
      addMessageSuccess({
        message,
        options: {
          variant: variant as VariantType | undefined,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        },
        key: Date.now() + Math.random().toString(),
      })
    );
  };

export type AddMessageType = typeof addMessage;

export const removeMessage = (key: string) => (dispatch: Dispatch) => {
  dispatch(removeMessageSuccess(key));
};

export type RemoveMessageType = typeof removeMessage;

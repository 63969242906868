import { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { Method } from 'axios';
import { UnknownObject } from 'common/types';
import axios from './axios';

export type ParamType = 'header' | 'query' | 'data' | 'body' | 'url';

export type ParamKey = string;
export type ParamValue = string | number | boolean | undefined;
type Params = Record<ParamKey, ParamValue>;

export type ServiceConfig<U extends ParamKey = string, T = Params> = {
  url: string;
  method: Method;
  authRequired?: boolean;
  paramTypes?: Record<U, ParamType>;
  paramDefaults?: Partial<T>;
  baseUrl?: string;
  profileOrgId?: string;
};

type ParameterCategories = {
  headerParams: { [key: ParamKey]: ParamValue };
  urlParams: { [key: ParamKey]: ParamValue };
  queryParams: { [key: ParamKey]: ParamValue };
  data: unknown;
};

export const getLanguageHeaders = () => {
  const language = localStorage.getItem('appLanguage') || 'en';

  return {
    'Accept-Language': language,
  };
};

/**
 * Transforms a map of parameters to a ? and & separated URI encoded query string.
 */
export const encodeQueryParams = (queryParams: UnknownObject): string => {
  if (!queryParams) {
    return '';
  }

  const paramList = Object.keys(queryParams).reduce((acc, name: string) => {
    const value = queryParams[name];
    const encodedName = encodeURIComponent(name);
    if (Array.isArray(value)) {
      return [...acc, ...value.map((item) => `${encodedName}=${encodeURIComponent(item)}`)];
    }

    return [...acc, `${encodedName}=${encodeURIComponent(value)}`];
  }, [] as string[]);

  return paramList.length ? `?${paramList.join('&')}` : '';
};

/**
 * Replaces parameters with values in a URL with URI encoding.
 */
export const replaceUrlParams = (url: string, params: UnknownObject) =>
  Object.keys(params).reduce((url, key) => {
    const regexp = new RegExp(`\\{${key}\\}`, 'g');
    return url.replace(regexp, encodeURIComponent(params[key]));
  }, url);

/**
 * Convert service option objects to API requesting functions
 */
const createService = (config: ServiceConfig<ParamKey, Params>) => {
  const { authRequired, method, paramTypes, paramDefaults, ...otherConfig } = config;

  return async (params: Params) => {
    const allParams = { ...paramDefaults, ...params };

    const paramCategories = Object.keys(allParams).reduce(
      (result, paramName) => {
        const paramType = (paramTypes && paramTypes[paramName]) || 'url';
        const paramValue = allParams[paramName];

        switch (paramType) {
          case 'header': {
            result.headerParams[paramName] = paramValue;
            return result;
          }
          case 'url': {
            result.urlParams[paramName] = paramValue;
            return result;
          }
          case 'query': {
            result.queryParams[paramName] = paramValue;
            return result;
          }
          case 'data': {
            result.data = paramValue;
            return result;
          }
          default: {
            return result;
          }
        }
      },
      {
        headerParams: {},
        urlParams: {},
        queryParams: {},
        data: null,
      } as ParameterCategories
    );

    const { headerParams, urlParams, queryParams, data } = paramCategories;

    const axiosConfig = {
      ...otherConfig,
      authRequired,
      headers: {
        Accept: 'application/json',
        ...getLanguageHeaders(),
        ...headerParams,
      },
      method,
      url: replaceUrlParams(config.url, urlParams as UnknownObject) + encodeQueryParams(queryParams as UnknownObject),
    } as AxiosRequestConfig;

    if (data) {
      Object.assign(axiosConfig, { data });
    }

    return axios(axiosConfig);
  };
};

/**
 * Use this function to add functions to call after response error
 *
 */
const createServices = <T extends Record<ServiceNames, ServiceConfig>, ServiceNames extends string = string>(
  servicesCfg: T,
  baseCfg?: ServiceConfig
) => {
  const services = (Object.keys(servicesCfg) as ServiceNames[]).reduce(
    (result, name) => ({
      ...result,
      [name]: createService({ ...baseCfg, ...servicesCfg[name] }),
    }),
    {} as {
      [Service in keyof T]: <
        Params = T[Service]['paramTypes'] extends undefined ? undefined : Record<NonNullable<keyof T[Service]['paramTypes']>, unknown>,
        Response = unknown
      >(
        params?: Params
      ) => Promise<AxiosResponse<Response>>;
    }
  );

  return services;
};

export default createServices;

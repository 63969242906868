import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'api/services';
import { State } from 'state/store';
import { BaseState } from 'modules/fleet-management/state/types/outlineTypes';

export type Configuration = {
  id: number;
  name: string;
  description: string;
};

export type Certificate = {
  id: number;
  name: string;
};

export type Subfleet = {
  id: number;
  name: string;
};

export type UnconfiguredAircraftConfig = {
  configurations: Configuration[];
  certificates: Certificate[];
  subfleets: Subfleet[];
};

export interface UnconfiguredAircraftConfigState extends BaseState {
  config: UnconfiguredAircraftConfig;
}

const initialState: UnconfiguredAircraftConfigState = {
  config: {
    configurations: [],
    certificates: [],
    subfleets: [],
  },
  error: undefined,
};

export const fetchUnconfiguredAircraftConfig = createAsyncThunk('fleets/fetchUnconfiguredAircraftConfig', async () => {
  const response = await services.getUnconfiguredAircraftConfig();
  return response.data as UnconfiguredAircraftConfig;
});

export const unconfiguredAircraftConfigSlice = createSlice({
  name: 'unconfiguredAircraftConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnconfiguredAircraftConfig.pending, (state) => {
        state.error = undefined;
      })
      .addCase(fetchUnconfiguredAircraftConfig.fulfilled, (state, action) => {
        state.error = undefined;
        state.config = action.payload;
      })
      .addCase(fetchUnconfiguredAircraftConfig.rejected, (state, payload) => {
        state.error = payload.error.message;
        state.config = {
          configurations: [],
          certificates: [],
          subfleets: [],
        };
      });
  },
});

export const selectUnconfiguredAircraftConfig = (state: State) => state.unconfiguredAircraftConfig.config;

export default unconfiguredAircraftConfigSlice.reducer;

export enum SlotItemSubType {
  SPM = 'SPM',
  ISD = 'ISD',
  WAP = 'WAP',
  MCU = 'MCU',
  ADT = 'ADT',
  MODEM = 'MODEM',
  CT = 'CT',
  CABINCTRL = 'CABINCTRL',
  SERVER = 'SERVER',
  ARINC = 'ARINC',
}

export enum HardwareItemSubType {
  SPM = 'SPM',
  ISD = 'ISD',
  WAP = 'WAP',
  MCU = 'MCU',
  ADT = 'ADT',
  MODEM = 'MODEM',
  CT = 'CT',
  CABINCTRL = 'CABINCTRL',
  SERVER = 'SERVER',
  ARINC = 'ARINC',
}

export enum SoftwareItemSubType {
  OS = 'OS',
  SYSTEM = 'SYSTEM',
  //...
}

export enum SoftwareComponentInstallationSubType {
  CLIENT = 'CLIENT',
  NATIVE = 'NATIVE',
  OS = 'OS',
  SERVICE = 'SERVICE',
  STANDALONE = 'STANDALONE',
}

export enum ContentDeploymentSubType {
  MOVIE = 'MOVIE',
  PODCAST_EPISODE = 'PODCAST_EPISODE',
  SERIES_EPISODE = 'SERIES_EPISODE',
}

export type ItemSubType =
  | SlotItemSubType
  | HardwareItemSubType
  | SoftwareItemSubType
  | SoftwareComponentInstallationSubType
  | ContentDeploymentSubType;

import { Subarea } from 'modules/fleet-management/state/outline/aircraftOutlineInteractionSlice';
import { CanvasItem } from 'modules/fleet-management/state/types/outlineTypes';

// Finds the items from the items parameter that are in the specified grid cell
const findItemsInGridCell = <T extends CanvasItem>(gridX: number, gridY: number, items: T[]): T[] =>
  items.filter((item) => item.gridRow === gridX && item.gridCol === gridY);

const isItemInCell = <T extends CanvasItem>(gridX: number, gridY: number, item: Subarea | T): boolean =>
  item.gridRow === gridX && item.gridCol === gridY;

export { findItemsInGridCell, isItemInCell };

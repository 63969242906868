import { useCallback } from 'react';
import { useNavigate as useNavigateRouterDOM } from 'react-router-dom';

const useNavigate = () => {
  const navigate = useNavigateRouterDOM();

  const navigateTo = useCallback(
    (type, path) => {
      if (type === 'push') {
        navigate(path);
      } else {
        navigate(path, { replace: true });
      }
    },
    [navigate]
  );

  return navigateTo;
};

export default useNavigate;

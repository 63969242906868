import { AIRCRAFT_FUSELAGE_IMAGE, TEST_RACK_IMAGE } from 'modules/fleet-management/common/images';
import { TEST_RACK_AC_TYPE } from 'modules/fleet-management/view/fleets/aircraft/outline/constants/outlineConstants';

export type BackgroundImageProps = {
  imageWidth: number;
  imageHeight: number;
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
};

export enum FuselageImage {
  TEST_RACK,
  DEFAULT,
}

const getBackgroundImageByType = (type: string): FuselageImage =>
  type === TEST_RACK_AC_TYPE ? FuselageImage.TEST_RACK : FuselageImage.DEFAULT;

const getBackgroundImageRatiosByType = (type: FuselageImage): BackgroundImageProps => {
  if (type === FuselageImage.TEST_RACK) {
    return {
      imageWidth: TEST_RACK_IMAGE.width,
      imageHeight: TEST_RACK_IMAGE.height,
      padding: {
        top: 16,
        right: 26,
        bottom: 20,
        left: 16,
      },
    };
  }

  return {
    imageWidth: AIRCRAFT_FUSELAGE_IMAGE.width,
    imageHeight: AIRCRAFT_FUSELAGE_IMAGE.height,
    padding: {
      top: 50,
      right: 20,
      bottom: 46,
      left: 110,
    },
  };
};

export { getBackgroundImageByType, getBackgroundImageRatiosByType };

import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { CONTENT_DEPLOYMENT_DATA_LOCATION } from 'common/environment';
import store from 'state/store';
import { ContentDeployment } from 'modules/fleet-management/state/types/outlineTypes';
import { sortEventsByTimestamp } from 'modules/fleet-management/utils/functions/sortEventsByTimestamp';
import fetchContentFromAzureBlob from 'modules/fleet-management/utils/functions/fetchContentFromAzureDataService';

const contentDeploymentAdapter = createEntityAdapter<ContentDeployment>({
  selectId: (item: ContentDeployment) => item.id,
});

export type ContentDeploymentState = {
  items: EntityState<ContentDeployment>;
  error?: Error;
};

export const initialState: ContentDeploymentState = {
  items: contentDeploymentAdapter.getInitialState(),
  error: undefined,
};

export const fetchContentDeploymentsByTailsign = createAsyncThunk<ContentDeployment[], string>(
  'fleets/fetchContentDeploymentsByTailsign',
  async (tailsign: string): Promise<ContentDeployment[]> => {
    const contentDeploymentJSON: ContentDeployment[] = await fetchContentFromAzureBlob(CONTENT_DEPLOYMENT_DATA_LOCATION, tailsign);
    return contentDeploymentJSON;
  }
);

const contentDeploymentSlice = createSlice({
  name: 'contentDeployment',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchContentDeploymentsByTailsign.pending, (state) => {
        state.error = undefined;
        contentDeploymentAdapter.removeAll(state.items);
      })
      .addCase(fetchContentDeploymentsByTailsign.fulfilled, (state, action) => {
        state.error = undefined;
        const sortedData = sortEventsByTimestamp(action.payload);
        contentDeploymentAdapter.setAll(state.items, sortedData);
      })
      .addCase(fetchContentDeploymentsByTailsign.rejected, (state, payload) => {
        state.error = payload.error as Error;
        contentDeploymentAdapter.removeAll(state.items);
      }),
});

type RootState = ReturnType<typeof store.getState>;

const contentDeploymentsEntitySelectors = contentDeploymentAdapter.getSelectors<RootState>((state) => state.contentDeployment.items);
export const selectContentDeployments = contentDeploymentsEntitySelectors.selectAll;

export default contentDeploymentSlice.reducer;

import {
  AircraftFuselage,
  CheckIcon,
  CloseIcon,
  ErrorBadge,
  ErrorIcon,
  FilterBadge,
  MinusIcon,
  MissingIcon,
  TestRack,
} from 'modules/fleet-management/assets/svg/outline/common';
import { AudioIcon, MovieIcon, SeriesIcon } from 'modules/fleet-management/assets/svg/outline/contentDeployment';
import {
  ADTIcon,
  CABINCTRLIcon,
  CTIcon,
  ISDIcon,
  MCUIcon,
  MODEMIcon,
  SERVERIcon,
  SPMIcon,
  WAPIcon,
} from 'modules/fleet-management/assets/svg/outline/hardware';
import {
  ADTIcon as ADTSlotIcon,
  CABINCTRLIcon as CABINCTRLSlotIcon,
  CTIcon as CTSlotIcon,
  ISDIcon as ISDSlotIcon,
  MCUIcon as MCUSlotIcon,
  MODEMIcon as MODEMSlotIcon,
  SERVERIcon as SERVERSlotIcon,
  SPMIcon as SPMSlotIcon,
  WAPIcon as WAPSlotIcon,
} from 'modules/fleet-management/assets/svg/outline/lopa';
import {
  ClientSWIcon,
  NativeSWIcon,
  OperatingSystemSWIcon,
  ServiceSWIcon,
  StandaloneSWIcon,
} from 'modules/fleet-management/assets/svg/outline/softwareComponentInstallation';
import {
  ContentDeploymentSubType,
  HardwareItemSubType,
  SlotItemSubType,
  SoftwareComponentInstallationSubType,
} from 'modules/fleet-management/state/types/itemSubTypes';

const getSlotIcons = () => {
  const lopaItems = new Map();
  lopaItems.set(SlotItemSubType.ISD, createSVGImage(ISDSlotIcon));
  lopaItems.set(SlotItemSubType.SPM, createSVGImage(SPMSlotIcon));
  lopaItems.set(SlotItemSubType.WAP, createSVGImage(WAPSlotIcon));
  lopaItems.set(SlotItemSubType.MCU, createSVGImage(MCUSlotIcon));
  lopaItems.set(SlotItemSubType.ADT, createSVGImage(ADTSlotIcon));
  lopaItems.set(SlotItemSubType.MODEM, createSVGImage(MODEMSlotIcon));
  lopaItems.set(SlotItemSubType.CT, createSVGImage(CTSlotIcon));
  lopaItems.set(SlotItemSubType.CABINCTRL, createSVGImage(CABINCTRLSlotIcon));
  lopaItems.set(SlotItemSubType.SERVER, createSVGImage(SERVERSlotIcon));
  return lopaItems;
};

const getHardwareIcons = () => {
  const hardwareItems = new Map();
  hardwareItems.set(HardwareItemSubType.ISD, createSVGImage(ISDIcon));
  hardwareItems.set(HardwareItemSubType.SPM, createSVGImage(SPMIcon));
  hardwareItems.set(HardwareItemSubType.WAP, createSVGImage(WAPIcon));
  hardwareItems.set(HardwareItemSubType.MCU, createSVGImage(MCUIcon));
  hardwareItems.set(HardwareItemSubType.ADT, createSVGImage(ADTIcon));
  hardwareItems.set(HardwareItemSubType.MODEM, createSVGImage(MODEMIcon));
  hardwareItems.set(HardwareItemSubType.CT, createSVGImage(CTIcon));
  hardwareItems.set(HardwareItemSubType.CABINCTRL, createSVGImage(CABINCTRLIcon));
  hardwareItems.set(HardwareItemSubType.SERVER, createSVGImage(SERVERIcon));
  return hardwareItems;
};

const getSoftwareComponentInstallationIcons = () => {
  const iconMap = new Map();
  const { CLIENT, OS, NATIVE, SERVICE, STANDALONE } = SoftwareComponentInstallationSubType;
  iconMap.set(CLIENT, createSVGImage(ClientSWIcon));
  iconMap.set(OS, createSVGImage(OperatingSystemSWIcon));
  iconMap.set(NATIVE, createSVGImage(NativeSWIcon));
  iconMap.set(SERVICE, createSVGImage(ServiceSWIcon));
  iconMap.set(STANDALONE, createSVGImage(StandaloneSWIcon));
  return iconMap;
};

const getContentDeploymentIcons = () => {
  const iconMap = new Map();
  const { MOVIE, SERIES_EPISODE, PODCAST_EPISODE } = ContentDeploymentSubType;
  iconMap.set(MOVIE, createSVGImage(MovieIcon));
  iconMap.set(SERIES_EPISODE, createSVGImage(SeriesIcon));
  iconMap.set(PODCAST_EPISODE, createSVGImage(AudioIcon));
  return iconMap;
};

const createSVGImage = (src: string): HTMLImageElement => {
  const image = new window.Image();
  image.src = src;
  return image;
};

const AIRCRAFT_FUSELAGE_IMAGE = createSVGImage(AircraftFuselage);
const TEST_RACK_IMAGE = createSVGImage(TestRack);
const MISSING = createSVGImage(MissingIcon);

const LOPA_IMAGES = getSlotIcons();
const HARDWARE_IMAGES = getHardwareIcons();
const SOFTWARE_COMPONENT_INSTALLATION_IMAGES = getSoftwareComponentInstallationIcons();
const CONTENT_DEPLOYMENT_IMAGES = getContentDeploymentIcons();

const HEALTH_IMAGES = {
  OK: createSVGImage(CheckIcon),
  ERROR: createSVGImage(ErrorIcon),
  EMPTY: createSVGImage(MinusIcon),
};

const HEALTH_BADGE = {
  ERROR: createSVGImage(ErrorBadge),
};

const FILTER_BADGE = createSVGImage(FilterBadge);

const CANCEL_BADGE = createSVGImage(CloseIcon);

export {
  AIRCRAFT_FUSELAGE_IMAGE,
  CANCEL_BADGE,
  FILTER_BADGE,
  HARDWARE_IMAGES,
  HEALTH_BADGE,
  HEALTH_IMAGES,
  LOPA_IMAGES,
  MISSING,
  SOFTWARE_COMPONENT_INSTALLATION_IMAGES,
  CONTENT_DEPLOYMENT_IMAGES,
  TEST_RACK_IMAGE,
};

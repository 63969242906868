export {};

declare global {
  interface Window {
    __RUNTIME_CONFIG__: {
      KEYCLOAK_URL: string;
      KEYCLOAK_REALM: string;
      KEYCLOAK_CLIENT_ID: string;
      ENV_LABEL: string;
      API_BASE_URL: string;
      USER_MANAGEMENT_WEB_API_URL: string;
      IMAGE_TAG: string;
      HARDWARE_DATA_LOCATION: string;
      SOFTWARE_COMPONENT_INSTALLATION_DATA_LOCATION: string;
      CONTENT_DEPLOYMENT_DATA_LOCATION: string;
      ACM_REPOSITORY_URL: string;
    };
  }
}

const KEYCLOAK_URL = window.__RUNTIME_CONFIG__.KEYCLOAK_URL;
const KEYCLOAK_REALM = window.__RUNTIME_CONFIG__.KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = window.__RUNTIME_CONFIG__.KEYCLOAK_CLIENT_ID;
const ENV_LABEL = window.__RUNTIME_CONFIG__.ENV_LABEL;
const API_BASE_URL = window.__RUNTIME_CONFIG__.API_BASE_URL;
const USER_MANAGEMENT_WEB_API_URL = window.__RUNTIME_CONFIG__.USER_MANAGEMENT_WEB_API_URL;
const IMAGE_TAG = window.__RUNTIME_CONFIG__.IMAGE_TAG;
const HARDWARE_DATA_LOCATION = window.__RUNTIME_CONFIG__.HARDWARE_DATA_LOCATION;
const SOFTWARE_COMPONENT_INSTALLATION_DATA_LOCATION = window.__RUNTIME_CONFIG__.SOFTWARE_COMPONENT_INSTALLATION_DATA_LOCATION;
const CONTENT_DEPLOYMENT_DATA_LOCATION = window.__RUNTIME_CONFIG__.CONTENT_DEPLOYMENT_DATA_LOCATION;
const ACM_REPOSITORY_URL = window.__RUNTIME_CONFIG__.ACM_REPOSITORY_URL;

export {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  API_BASE_URL,
  USER_MANAGEMENT_WEB_API_URL,
  ENV_LABEL,
  IMAGE_TAG,
  HARDWARE_DATA_LOCATION,
  SOFTWARE_COMPONENT_INSTALLATION_DATA_LOCATION,
  CONTENT_DEPLOYMENT_DATA_LOCATION,
  ACM_REPOSITORY_URL,
};

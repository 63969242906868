import ReactDOM from 'react-dom/client';
import { themeCompact as AERQTheme } from '@control-tower/aerq-ui-library';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material';
import Root from './Root';
import './i18n';

export const opsPortalTheme = {
  ...AERQTheme,
  palette: {
    ...AERQTheme.palette,
    // The secondary color is missing in the common-ui-lib, so we had to add it here.
    secondary: {
      main: '#757575',
    },
    success: {
      ...AERQTheme.palette.success,
      100: 'rgba(145, 197, 70, 0.1)',
      200: 'rgba(145, 197, 70, 0.2)',
    },
    error: {
      ...AERQTheme.palette.error,
      50: '#DF3F410D',
      100: 'rgba(223, 63, 65, 0.1)',
      200: 'rgba(223, 63, 65, 0.2)',
    },
    warning: {
      ...AERQTheme.palette.warning,
      100: '#DF953F1A',
    },
    info: {
      ...AERQTheme.palette.info, // main: #7894DB
      50: '#7894DB0D',
      dark: '#647BB6',
    },
    grey: {
      ...AERQTheme.palette.grey,
      drawerItemHover: '#F5EEEE',
    },
    icon: {
      hover: '#EFA780',
    },
    badge: {
      success: '#5A7C27',
      warning: '#C97C22',
      error: '#C92224',
      disabled: '#616161',
      bg: {
        success: '#91C5461A',
        warning: '#DF953F1A',
        error: '#DF3F4114',
        disabled: '#00000014',
      },
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={opsPortalTheme}>
      <CssBaseline />
      <Root />
    </MuiThemeProvider>
  </StyledEngineProvider>
);

import { DeckDimensions } from 'modules/fleet-management/state/stage/stageSlice';
import { Area } from 'modules/fleet-management/state/types/outlineTypes';
import { AREA_PADDING, AREA_LENGTH_MODIFIER_VALUE } from 'modules/fleet-management/view/fleets/aircraft/outline/constants/outlineConstants';

const calculateAreaLocations = (areas: Area[], deck: DeckDimensions) => {
  const { allAreaRows, averageAreaRows } = analyzeAreas(areas);
  let currentPosition = 0;
  const topPadding = deck.height * 0.1;
  const bottomPadding = deck.height * 0.2;
  return areas.map((area) => {
    const lengthOfArea = areaLengthFormula(deck.length, averageAreaRows, allAreaRows, area.rows);
    const newArea = {
      ...area,
      coordinates: {
        x: currentPosition + AREA_PADDING,
        y: topPadding,
        width: lengthOfArea - AREA_PADDING,
        height: deck.height - (topPadding + bottomPadding),
      },
    };

    currentPosition += lengthOfArea;

    return newArea;
  });
};

export default calculateAreaLocations;

const analyzeAreas = (areas: Area[]) => {
  const rowCounts: number[] = [];
  const allAreaRows = areas.reduce((accumulator, currentValue) => {
    rowCounts.push(currentValue.rows);
    return accumulator + currentValue.rows;
  }, 0);
  const averageAreaRows = rowCounts.reduce((a, b) => a + b, 0) / rowCounts.length;
  return { allAreaRows, averageAreaRows };
};

const areaLengthFormula = (deckLength: number, averageRows: number, maxRows: number, currentRows: number) => {
  const baseLength = currentRows / maxRows;
  const modifyWith = (AREA_LENGTH_MODIFIER_VALUE * (averageRows - currentRows)) / maxRows;
  return deckLength * (baseLength + modifyWith);
};

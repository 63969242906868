const ROUTES_FLEET_MANAGEMENT = {
  FLEETS: '/fleet-management/fleets',
  NEW_AC: '/fleet-management/fleets/aircraft/new',
  AC_OUTLINE: '/fleet-management/fleets/aircraft/outline',
};

const ROUTES_INTEGRATION_CENTER = {
  INTEGRATION_CENTER: '/integration-center',
  CONFIGURATION_ITEMS: 'configuration-items',
  CONFIGURATIONS: 'configurations',
  ENVIRONMENTS: 'environments',
};

export const ROUTES = {
  ORGANIZATION_SELECTOR: '/organization-selector',
  ...ROUTES_FLEET_MANAGEMENT,
  ...ROUTES_INTEGRATION_CENTER,
};

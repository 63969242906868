import { ContentContainerTemplate, PageContent } from '@control-tower/aerq-ui-library';
import NotFound from './NotFound';

const NotFoundPage = () => (
  <PageContent>
    <ContentContainerTemplate className="">
      <NotFound />
    </ContentContainerTemplate>
  </PageContent>
);
export default NotFoundPage;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectHardwareDevices } from 'modules/fleet-management/state/hardware/hardwareSlice';
import { selectLopaSlots } from 'modules/fleet-management/state/lopa/lopaSlice';
import { State } from 'state/store';
import { Area, CanvasItem } from 'modules/fleet-management/state/types/outlineTypes';
import { findItemsInGridCell } from 'modules/fleet-management/utils/functions/canvasItemGridUtil';
import { hasItemGroupHealthIssue } from 'modules/fleet-management/utils/functions/checkHealthStatus';
import { selectSoftwareComponentInstallations } from 'modules/fleet-management/state/software/softwareComponentInstallationSlice';
import { selectContentDeployments } from 'modules/fleet-management/state/content/contentDeploymentSlice';
import DRAWER_WIDTH from 'modules/fleet-management/view/components/LeftSideDrawerConstants';

export type Subarea = Pick<CanvasItem, 'areaId' | 'gridRow' | 'gridCol'> & { name: string };

export type FilteredAreaDetails = {
  areaId: number;
  col: number | undefined;
  row: number | undefined;
};

export interface AircraftOutlineCameraState {
  scale: number;
  x: number;
  y: number;
}

export interface LeftDrawerState {
  open: boolean;
  visible: boolean;
}

export enum RADIAL_MENU_CENTER_ICONS {
  CLOSE = 'CLOSE',
}

export interface RadialMenuState {
  visible: boolean;
  position: {
    x: number;
    y: number;
  };
  centerIcon: RADIAL_MENU_CENTER_ICONS;
}

export interface AircraftOutlineInteractionState {
  camera: AircraftOutlineCameraState;
  leftDrawer: LeftDrawerState;
  highlightedItem: CanvasItem | undefined;
  selectedItem: CanvasItem | undefined;
  selectedStatusViewArea: Area | undefined;
  selectedStatusViewSubArea: Subarea | undefined;
  filteredArea: FilteredAreaDetails | undefined;
  cursorStyle: string;
  radialMenu: RadialMenuState;
}

export const INITIAL_CAMERA_STATE = {
  scale: 1,
  x: 0,
  y: 0,
};

export const INITIAL_RADIAL_MENU_STATE = {
  visible: false,
  position: {
    x: 0,
    y: 0,
  },
  centerIcon: RADIAL_MENU_CENTER_ICONS.CLOSE,
};

const initialState: AircraftOutlineInteractionState = {
  camera: { ...INITIAL_CAMERA_STATE },
  leftDrawer: {
    open: false,
    visible: false,
  },
  highlightedItem: undefined,
  selectedItem: undefined,
  selectedStatusViewArea: undefined,
  selectedStatusViewSubArea: undefined,
  filteredArea: undefined,
  cursorStyle: 'default',
  radialMenu: { ...INITIAL_RADIAL_MENU_STATE },
};

export const aircraftOutlineInteractionSlice = createSlice({
  name: 'aircraftOutlineInteractionStatus',
  initialState,
  reducers: {
    setAircraftOutlineCameraState: (state, action: PayloadAction<AircraftOutlineCameraState>) => ({
      ...state,
      camera: { ...action.payload },
    }),
    resetHighlightingOfItem: (state) => ({ ...state, highlightedItem: undefined }),
    highlightItem: (state, action: PayloadAction<CanvasItem>) => ({ ...state, highlightedItem: action.payload }),
    setSelectedItem: (state, action: PayloadAction<CanvasItem>) => ({ ...state, selectedItem: action.payload }),
    resetSelectedItem: (state) => ({ ...state, selectedItem: undefined }),
    setselectedStatusViewArea: (state, action: PayloadAction<Area>) => ({
      ...state,
      selectedStatusViewArea: action.payload,
      selectedStatusViewSubArea: undefined,
      selectedItem: undefined,
    }),
    resetselectedStatusViewArea: (state) => ({ ...state, selectedStatusViewArea: undefined }),
    setselectedStatusViewSubArea: (state, action: PayloadAction<Subarea>) => ({
      ...state,
      selectedStatusViewArea: undefined,
      selectedStatusViewSubArea: action.payload,
      selectedItem: undefined,
    }),
    resetselectedStatusViewSubArea: (state) => ({ ...state, selectedStatusViewSubArea: undefined }),
    setFilteredArea: (state, action: PayloadAction<FilteredAreaDetails>) => ({
      ...state,
      filteredArea: { ...action.payload },
    }),
    resetFilteredArea: (state) => ({ ...state, filteredArea: undefined }),
    setLeftDrawer: (state, action: PayloadAction<LeftDrawerState>) => {
      const { payload } = action;
      const { camera: currentCameraState, leftDrawer } = state;
      const drawerStateUnchanged = payload.open === leftDrawer.open;
      const newPositionX = payload.open ? currentCameraState.x + DRAWER_WIDTH.SINGLE / 2 : currentCameraState.x - DRAWER_WIDTH.SINGLE / 2;
      return {
        ...state,
        leftDrawer: payload,
        camera: {
          ...currentCameraState,
          x: drawerStateUnchanged ? currentCameraState.x : newPositionX,
        },
      };
    },
    setCursorStyle: (state, action: PayloadAction<string>) => ({ ...state, cursorStyle: action.payload }),
    setRadialMenuState: (state, action: PayloadAction<RadialMenuState>) => ({
      ...state,
      radialMenu: { ...action.payload },
    }),
    resetRadialMenuState: (state) => ({ ...state, radialMenu: { ...INITIAL_RADIAL_MENU_STATE } }),
  },
});

export const selectSelectedItem = (state: State): CanvasItem | undefined => state.aircraftOutlineInteraction.selectedItem;

export const selectedStatusViewAreaForDetails = (state: State): Area | undefined => state.aircraftOutlineInteraction.selectedStatusViewArea;

export const selectedStatusViewSubAreaForDetails = (state: State): Subarea | undefined =>
  state.aircraftOutlineInteraction.selectedStatusViewSubArea;

export const selectFilteredArea = (state: State): FilteredAreaDetails | undefined => state.aircraftOutlineInteraction.filteredArea;

export const selectCameraStatus = (state: State): AircraftOutlineCameraState => state.aircraftOutlineInteraction.camera;

export const selectStatusViewBorderWidth = (state: State): number => 1.5 / state.aircraftOutlineInteraction.camera.scale;

export const selectHighLightedItem = (state: State): CanvasItem | undefined => state.aircraftOutlineInteraction.highlightedItem;

export const selectLeftDrawerState = (state: State): LeftDrawerState => state.aircraftOutlineInteraction.leftDrawer;

export const selectRadialMenuState = (state: State): RadialMenuState => state.aircraftOutlineInteraction.radialMenu;

type SelectGroupCoordinateProps = {
  areaId: number;
  gridX?: number;
  gridY?: number;
};

// selects all canvas items in no particular order
export const selectCanvasItems = createSelector(
  [selectHardwareDevices, selectLopaSlots, selectSoftwareComponentInstallations, selectContentDeployments],
  (devices, slots, softwareComponentInstallations, contentDeployments) => [
    ...devices,
    ...slots,
    ...softwareComponentInstallations,
    ...contentDeployments,
  ]
);

// selects items in an item group (either a low or high detail group, depending on parameters)
export const selectGroupItems = createSelector(
  [selectCanvasItems, (_, props: SelectGroupCoordinateProps) => props],
  (items, { areaId, gridX, gridY }) => {
    let relevantItems = items.filter((item) => item.areaId === areaId);

    if (gridX !== undefined && gridY !== undefined) {
      relevantItems = findItemsInGridCell(gridX, gridY, relevantItems);
    }
    return relevantItems;
  }
);

// returns whether or not any item in the requested group (low or high detail group, depending on parameters) has a health issue
export const selectGroupHasHealthIssue = createSelector(
  [(state: State, props: SelectGroupCoordinateProps) => selectGroupItems(state, props)],
  (items) => hasItemGroupHealthIssue(items)
);

export const selectCursorStyle = (state: State) => state.aircraftOutlineInteraction.cursorStyle;

export default aircraftOutlineInteractionSlice.reducer;

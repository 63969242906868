import { AERQNavigation, AuthorizationError, DemoModeFlyout, ProfileChangedFlyout } from '@control-tower/aerq-navigation-library';
import setupMessageHook from 'api/interceptors/messages';
import { initServices } from 'api/services';
import { IMAGE_TAG, KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL, USER_MANAGEMENT_WEB_API_URL } from 'common/environment';
import useNavigate from 'common/hooks/useNavigate';
import Keycloak from 'keycloak-js';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Matching, connect } from 'react-redux';
import { AddMessageType, addMessage } from 'state/message/actions';
import { Dispatch } from 'state/store';
import { hideLoadingOverlay } from 'utils/functions/toggleLoadingOverlay';
import App from './App';
import extractUserRoles from 'utils/functions/userRoleUtil';

console.info(`Version: '${IMAGE_TAG}'`);

const AppNavigation: FunctionComponent<
  Matching<
    { addMessage: (message: string, variant: string | undefined) => void },
    { addMessage: (message: string, variant: string | undefined) => (dispatch: Dispatch) => void }
  >
> = (props) => {
  const { addMessage } = props;
  const translation = useTranslation('fleetManagement');

  const [userRoles, setUserRoles] = useState<string[]>([]);

  useEffect(() => {
    // setup service interceptor
    setupMessageHook(addMessage as AddMessageType, translation);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleAERQNavigationError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      hideLoadingOverlay();

      if (error instanceof AuthorizationError) {
        // TODO: messagesDispatch(showErrorDialog(401, [{ code: 'NO_PERMISSION' }]));
        console.error('Nav lib error ', 'NO_PERMISSION', error);
        return;
      }

      if (error.response) {
        const { data, status } = error.response;
        console.error('Nav lib error ', status, data.messages, error);
        // TODO: messagesDispatch(showErrorDialog(status, data.messages));
        return;
      }

      if (error.request) {
        console.error('Nav lib error ', 404, error);
        // TODO: messagesDispatch(showErrorDialog(404));

        return;
      }

      console.error('Nav lib error', error);
      // TODO:  messagesDispatch(showErrorDialog());
    },
    []
  );

  const navigateTo = useNavigate();

  return (
    <AERQNavigation
      keycloakOptions={{
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
      }}
      onBeforeInit={() => {
        initServices({ profileOrgId: undefined });
      }}
      onAfterSelectProfile={(orgInfo: { profileOrgId?: string }, keycloak: Keycloak.KeycloakInstance | undefined) => {
        initServices(orgInfo, keycloak);
        setUserRoles(extractUserRoles(keycloak));
      }}
      userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL}
      onError={handleAERQNavigationError}
      navigateTo={navigateTo}
    >
      <ProfileChangedFlyout />
      <DemoModeFlyout />
      <App userRoles={userRoles} />
    </AERQNavigation>
  );
};

const mapDispatchToProps = {
  addMessage,
};

export default connect(null, mapDispatchToProps)(AppNavigation);
